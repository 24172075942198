import { dev } from '$app/environment'
import * as Sentry from '@sentry/sveltekit'
import type { HandleClientError } from '@sveltejs/kit'

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://e79d2720b9114e1cb1aff3ea21e7565a@o337482.ingest.sentry.io/5531592',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [new Sentry.Replay()],
	enabled: !dev,
})

// import * as Sentry from '@sentry/svelte'
// import { dev } from '$app/environment'

export const handleError: HandleClientError = Sentry.handleErrorWithSentry(
	({ error, event }) => {
		// example integration with https://sentry.io/
		console.error(error)
		// if (!dev) {
		// 	Sentry.captureException(error, { event })
		// }

		return {
			message: error.message,
			code: error.code ?? 'UNKNOWN',
		}
	},
)
